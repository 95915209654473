import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";
import { educationData, experienceData } from "../../constants/experienceEducation";


const Education = () => {
  return (
    <section className="section-margins">
      <div className="container">
        <div className="row">
          <div id="section-education" className="col-12 col-md-6 mb-5 mb-md-0">
            <Pagetitle title="Education" />
            <div className="timeline edu box-dark text-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
              <span className="dot"></span>
            </div>

          </div>
          <div id="section-experiences" className="col-12 col-md-6">
            <Pagetitle title="Experience" />
            <div className="timeline exp box-dark text-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
              <span className="dot"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Education;