export const educationData = [
  {
    id: 1,
    title: "React Certificate",
    years: "2022",
    content: "React the complete guide - Udemy Course",
  },
  {
    id: 2,
    title: "Master's degree",
    years: "2014 - 2020",
    content: "Computer Science & Engineering, University of Ioannina",
  },
  {
    id: 3,
    title: "Cypersecurity and Privacy Certificate",
    years: "2017",
    content: "Summer School organised by the EIT Digital Master School",
  },
  {
    id: 4,
    title: "CCNA Discovery",
    years: ["2011", "2012"],
    content: [
      "2011 - Working at a Small-to-Medium Business or ISP",
      "2012 - Networking for Home and Small Businesses.",
    ],
  },
];

export const experienceData = [
  {
    id: 1,
    title: "Full-Stack Developer",
    years: "Aug, 2020 - Present",
    content: "Full-Stack Developer at Svelta Courier Ltd",
  },
  {
    id: 2,
    title: "Full-Stack Web Developer",
    years: "Oct, 2020 - Aug, 2022",
    content: "Full-Stack Web Developer at Octta UK",
  },
  {
    id: 3,
    title: "Back-End Developer",
    years: "2017 - 2018",
    content: "IEEE Student Branch - University of Piraeus.",
  },
  {
    id: 4,
    title: "Application Developer",
    years: "2015 - 2016",
    content: "IEEE Student Branch - University of Ioannina",
  },
];
