import React, { useEffect, useRef, useState } from "react";
import SEOHeader from "../components/elements/SEOHeader";
import Header from "../components/layouts/Header";
import About from "../components/sections/About";
import Blogs from "../components/sections/Blogs";
import Contact from "../components/sections/Contact";
import Education from "../components/sections/Education";
import profilePicture from "../assets/images/avatar/profile.png";

const useElementOnScreen = (options) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      if (containerRef.current) observer.observe(containerRef.current)
    }
  }, [containerRef, options]);

  return [containerRef, isVisible]
}


function Homepage() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = () => {
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  const [containerRefImage, isVisibleImage] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5
  });

  const [containerRefHeading, isVisibleHeading] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  });

  return (
    <>
      <SEOHeader
        title="Andreas Petsas | Personal Blog"
        description="Hi, I'm Andreas Petsas wokring as Software Developer. I'm mainly interested in frontend development. I'm also interested in a volunteer job in non-profit environmental organizations."
        keywords="Andreas Petsas"
        href="https://andreaspetsas.com"
        ogType="website"
        ogImage={profilePicture}
      />
      <Header
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
        isVisibleImage={isVisibleImage}
        isVisibleHeading={isVisibleHeading}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div id="section-about">
          <About
            containerRefHeading={containerRefHeading}
            containerRefImage={containerRefImage}
            isVisibleImage={isVisibleImage}
            isVisibleHeading={isVisibleHeading}
          />
        </div>
        <div>
          <Education />
        </div>
        <div id="section-blogs">
          <Blogs />
        </div>
        <div id="section-contact">
          <Contact />
        </div>
      </main>
    </>
  );
}

export default Homepage;
