import React, { useState } from "react";
import { db } from '../../firebase/firebase'
import { collection, addDoc } from "firebase/firestore";
import Pagetitle from "../elements/Pagetitle";
import Failed from "../../assets/images/icons/failed.png";
import Success from "../../assets/images/icons/check.png";
import Warning from "../../assets/images/icons/warning.png";

const Contact = () => {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [message, setMessage] = useState(-1); // 0 failed, 1 success, 2 warning, -1 init

  const submitHandler = async (event) => {
    setMessage(2);
    event.preventDefault();
    if (!formdata.name) {
      setMessage(0);
    } else if (!formdata.email) {
      setMessage(0);
    } else if (!formdata.message) {
      setMessage(0);
    } else {
      const emailsCollection = collection(db, 'emails');
      await addDoc(emailsCollection, {
        name: formdata.name,
        email: formdata.email,
        message: formdata.message,
        time: new Date(),
      }).then(() => {
        setFormdata({
          name: "",
          email: "",
          message: "",
        });
        setMessage(1);
      }).catch(() => {
        setMessage(0);
      })
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <section className="section-margins">
      <div className="container">
        <Pagetitle title="Get in Touch" />

        <div className="row contact-info">
          <div className="col-12 col-md-4">
            <div className="lets-talk">
              <h3>Let's talk about everything!</h3>
              <p className="text-white">
                Don't like forms? Send me an{" "}
                <a href="mailto:andreas.petsas@hotmail.com">email</a>. 👋
              </p>
            </div>
          </div>

          <div className="col-12 col-md-8">
            <form
              id="contact-form"
              className="contact-form mt-6"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="column col-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="InputName"
                      placeholder="Your name"
                      onChange={handleChange}
                      value={formdata.name}
                    />
                  </div>
                </div>

                <div className="column col-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      name="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      value={formdata.email}
                    />
                  </div>
                </div>

                <div className="column col-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="InputMessage"
                      className="form-control"
                      rows="5"
                      placeholder="Message"
                      onChange={handleChange}
                      value={formdata.message}
                    ></textarea>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                name="submit"
                id="submit"
                value="Submit"
                className="btn btn-default"
              >
                Send Message
                {message !== -1 && <span>
                  {message === 0 && <img src={Failed} width="18" height="18" alt="failed icon" /> }
                  {message === 1 && <img src={Success} width="18" height="18" alt="success icon" />}
                  {message === 2 && <img src={Warning} width="18" height="18" alt="warning icon" />}
                </span>}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
