import React from "react";

function Timeline({ education }) {
  const { years, title, content } = education;
  return (
    <div className="timeline-container">
      <div className="content">
        {typeof content === "string" && <span className="time">{years}</span>}
        {typeof content !== "string" && <span className="time">{years[0]} - {years[years.length - 1]}</span>}
        <h3 className="title">{title}</h3>
        {typeof content === "string" && <p>{content}</p>}
        {typeof content !== "string" &&
          content.map((con, index) => {
            return <p key={index}>{con}</p>;
          })}
      </div>
    </div>
  );
}

export default Timeline;
