import React from "react";
import { Link } from "react-router-dom";

function Blog({ blogData }) {
  const { id, category, title, date, image, filesource } = blogData;
  const getNospaceTitle = (filesource) => {
    let tempArr = filesource.split("/");
    let fileName = tempArr[tempArr.length - 1];
    let getName = fileName.slice(0, -3);
    return getName;
  };
  return (
    <div className="blog-item rounded box-dark shadow-dark">
      <div className="thumb box-white">
        <Link to={`#`} className="post-category">
          <span className="category">{category}</span>
        </Link>
        <Link to={`blogs/${getNospaceTitle(filesource)}`}>
          <img src={image} alt={title} height="100%"/>
        </Link>
      </div>
      <div className="details">
        <h4 className="my-0 title">
          <Link
            to={`blogs/${getNospaceTitle(filesource)}`}
          >
            {title}
          </Link>
        </h4>
        <ul className="list-inline meta mb-0 mt-2">
          <li className="list-inline-item">{date}</li>
        </ul>
      </div>
    </div>
  );
}

export default Blog;
