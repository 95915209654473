import React, { useState, useEffect } from "react";
import SEOHeader from "../components/elements/SEOHeader";
import Header from "../components/layouts/Header";
import Blog from "../components/elements/Blog";
import Pagination from "../components/elements/Pagination";
import profilePicture from "../assets/images/avatar/profile.png";
import { blogItems } from "../constants/blogItems";

function Bloglist() {

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    setPosts(blogItems);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <SEOHeader
        title="Andreas Petsas | Personal Blog"
        description="This is my personal blog. Articles related to tech."
        keywords="Andreas Petsas"
        href="https://andreaspetsas.com/blogs"
        ogType="website"
        ogImage={profilePicture}
      />
      <Header
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"} >
        <div className="blog-page-section">
          <div className="container">
            <div className="row blog-wrapper">
              {currentPosts.map((blogItem) => (
                <div className="col-12 col-md-6 col-xl-4" key={blogItem.id}>
                  <Blog blogData={blogItem} />
                </div>
              ))}
            </div>
            {!(posts.length > postsPerPage) ? null : (
              <Pagination
                itemsPerPage={postsPerPage}
                totalItems={posts.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
}

export default Bloglist;
