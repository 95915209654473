import React from "react";
import ReactTypingEffect from "react-typing-effect";
import { aboutContent } from "../../constants/about";
import { ReactComponent as Stars } from "../../assets/stars.svg";
// import { useInView } from 'react-intersection-observer';

const About = ({
  containerRefImage, 
  containerRefHeading,
  isVisibleImage,
  isVisibleHeading,
}) => {

  return (
    <section className="section-margins">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-3">
            <div className="text-center text-md-left">
              <img ref={containerRefImage}
                src={aboutContent.image}
                alt={aboutContent.alt}
                width="200"
                height="200"
              />
            </div>
          </div>
          <div className="col-9 col-md-7 col-xl-5 d-flex justify-content-center justify-content-lg-start">
            <h2 className="align-self-center">
              <ReactTypingEffect
                text={["Nature lover", "Photography ethusiast", "cyclict", "climber", "mountaineer"]}
                staticText={"I'm a "}
                speed={60}
                eraseSpeed={65}
                typingDelay={10}
                eraseDelay={700}
              />
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row ml-0 mr-0 justify-content-center">
          <div className="col-12 col-md-6">
            <h1 ref={containerRefHeading}>Hi, I'm Andreas Petsas</h1>
            <h3>Working as Full-stack Developer</h3>
            <p>{aboutContent.text}</p>
            <a href="https://drive.google.com/file/d/1oQMretoCMgo3hsvj-LRRv_Wt0LL0m6KK/view?usp=sharing" 
              className="btn btn-default"
              target="_blank"
              rel="noreferrer"
            >
              Download CV
            </a>
          </div>
          <div className="col-12 col-md-6 position-relative mt-5 mt-md-0">
            <div className="row rounded text-white shadow-dark skills-background">

            </div>
            <div className="row rounded box-dark skills position-absolute">
              <div className="col-12">
                <h4>Key skills
                  <Stars />
                </h4>
              </div>
              <div className="col-6">
                <ul>
                  <li>React.js</li>
                  <li>Docker</li>
                  <li>.NET</li>
                  <li>AWS</li>
                  <li>.NET C#</li>
                </ul>
              </div>
              <div className="col-6">
                <ul>
                  <li>Git</li>
                  <li>SEO</li>
                  <li>Wordpress</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
