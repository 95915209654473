import React from "react";

function Pagetitle({ title }) {
  return (
    <>
        <h2 className="section-title mb-4">{title}</h2>
    </>
  );
}

export default Pagetitle;
