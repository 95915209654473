import React from "react";
import { HashLink } from "react-router-hash-link";
import { aboutContent } from "../../constants/about";

function Header({
  toggleMenu,
  headerToggler,
  isVisibleImage,
  isVisibleHeading
}) {

  const handleClasses = () => {
    let classes = "desktop-header d-flex align-items-start flex-column";
    if (toggleMenu) {
      classes += " open";
    }
    return classes;
  };

  const handleMobileClasses = () => {
    let classes = "mobile-header";
    if (toggleMenu) {
      classes += " open";
    }
    return classes;
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    headerToggler();
  }

  return (
    <>
      <header className={handleMobileClasses()}>
        <div className="container">
          <div className="menu-icon d-inline-flex w-100 justify-content-between align-items-center mr-4">
            <button onClick={headerToggler}>
              <span></span>
            </button>
            {!isVisibleHeading && 
              <p className="mb-0">Hi, I'm Andreas Petsas</p>
            }
            {!isVisibleImage && <img
              src={aboutContent.image}
              alt={aboutContent.alt}
              width="50"
              height="50"
            />}
          </div>
        </div>
      </header>
      <header className={handleClasses()}>
        <nav>
          <ul className="vertical-menu scrollspy">
            <li>
              <HashLink
                to="/#section-about"
                smooth
                scroll={el => scrollWithOffset(el)}
              >
                <i className="icon-user-following"></i>About
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#section-education"
                smooth
                scroll={el => scrollWithOffset(el)}
              >
                <i className="icon-graduation"></i>Education
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#section-experiences"
                smooth
                scroll={el => scrollWithOffset(el)}
              >
                <i className="icon-briefcase"></i>Experience
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#section-blogs"
                smooth
                scroll={el => scrollWithOffset(el)}
              >
                <i className="icon-note"></i>Blog
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#section-contact"
                smooth
                scroll={el => scrollWithOffset(el)}
              >
                <i className="icon-bubbles"></i>Contact
              </HashLink>
            </li>
          </ul>
        </nav>

        <div className="footer">
          <ul className="social-icons light list-inline mb-0 mt-4">
            <li className="list-inline-item">
              <a href="https://www.instagram.com/antreas_petsas/" target="_blank" rel="noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://github.com/AntreasPetsas" target="_blank" rel="noreferrer" >
                <i className="fab fa-github"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.linkedin.com/in/antreaspetsas/" target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
          <span className="copyright">
            &copy; {new Date().getFullYear()} Antreas Petsas.
          </span>
        </div>
      </header>
    </>
  );
}

export default Header;
