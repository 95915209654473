/*
My name is Ian Dunkerley, I'm a front-end developer based in Torquay, Devon, UK. I have developed many types of front-ends from well know DJ applications to Ecommerce booking platforms.
I'm passionate about cutting-edge, pixel-perfect, beautiful interfaces and intuitively implemented UX. 
*/
import profile from "../assets/images/avatar/profile.png";

export const aboutContent = {
    alt: "Antreas About Image",
    image: profile,
    text:
        'I am an energetic, ambitious and hardworking person who has developed a mature and responsible approach to every task I undertake or any situation I get presented with. \n \n I love doing outdoors activities. I like cycling, hiking, climbing and running.',
};

export const skills = [
    {
        id: 1,
        title: "Docker",
        color: "#FFD15C",
      },
      {
        id: 2,
        title: "Git",
        color: "#FF4C60",
      },
      {
        id: 3,
        title: "Photography",
        color: "#6C6CE5",
      },
      {
        id: 4,
        title: "React",
        color: "#44D7B6",
      },
      {
        id: 5,
        title: "Photography",
        color: "#FF4C60",
      },
      {
        id: 6,
        title: ".NET",
        color: "#FFD15C",
      },
      {
        id: 7,
        title: "WordPress",
        color: "#44D7B6",
      },
      {
        id: 8,
        title: "Development",
        color: "#6C6CE5",
      },
      {
        id: 9,
        title: "Photography",
        color: "#FFD15C",
      },
];

export const counters = [
    {
        id: 1,
        title: "Projects completed",
        count: 2,
        icon: "icon-fire",
      },
      {
        id: 2,
        title: "Cup of coffee",
        count: 5670,
        icon: "icon-cup",
      },
];
