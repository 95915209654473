import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import SEOHeader from "../components/elements/SEOHeader";
import Header from "../components/layouts/Header";
import profilePicture from "../assets/images/avatar/profile.png";

function BlogDetails(props) {
  const [content, setContent] = useState("");
  const blogFile = props.match.params.title;

  useEffect(() => {
    import(`../blogs/${blogFile}.md`)
      .then((res) => res.default)
      .then((res) => {
        fetch(res)
          .then((result) => result.text())
          .then((result) => setContent(result));
      });
  }, [content, blogFile]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <SEOHeader
        title="Andreas Petsas | Personal Blog"
        description="Hi, I'm Andreas Petsas wokring as Software Developer. I'm mainly interested"
        keywords="Andreas Petsas"
        href={`https://andreaspetsas.com/blogs/${blogFile}`}
        ogType="article"
        ogImage={profilePicture}
      />
      <Header
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="blog-page-section">
          <div className="container">
            <div className="blog-single p-30">
              <Markdown>{content}</Markdown>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default BlogDetails;
