import { Helmet } from 'react-helmet';

const SEOHeader = ({
    title, 
    description, 
    keywords,
    href,
    ogType,
    ogImage,
    children}) => {

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="title" content={title} />
            <meta name="keywords" content={keywords} />

            {/* Links */}
            <link rel="canonical" href={href} />

            {/* Open graph */}
            <meta property="og:type" content={ogType} />
            <meta property="og:url" content={href} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            {/* Twitter Card*/}
            <meta name="twitter:card" content="summary" /> 
            <meta name="twitter:site" content={href} /> 
            <meta name="twitter:title" content={title} /> 
            <meta name="twitter:description" content={description} /> 
            <meta name="twitter:image" content={ogImage} />

            {children}
        </Helmet>
    )
}

export default SEOHeader;