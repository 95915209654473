import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Bloglist from "./pages/Bloglist";
import BlogDetails from "./pages/BlogDetails";
import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/blogs/:title" component={BlogDetails} />
        <Route path="/blogs" component={Bloglist} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
