import Blog1File from "../assets/images/blog/react-logo.png";
import PersonalBlogImage from "../assets/images/blog/personalBlogPostImage.png"

export const blogItems = [
    {
        id: 1,
        title: "React Architecture: How to Structure and Organize a React Application",
        image: Blog1File,
        alt: "react architecture",
        filesource: "../../blogs/react-architecture.md",
        date: "09 February, 2022",
        category: "Javascript",
    },
    {
        id: 2,
        title: "Personal Blog Creation",
        image: PersonalBlogImage,
        alt: "Designed by vectorjuice / Freepik",
        filesource: "../../blogs/personal-blog-post.md",
        date: "19 October, 2022",
        category: "Project"
    }
]